const getUser = () => {
  const user = window.localStorage.getItem("agent");

  if (user) {
    return JSON.parse(user);
  }

  return null;
};

const setUser = (user) => {
  window.localStorage.setItem("agent", JSON.stringify(user));
};

const clearUser = () => {
  window.localStorage.removeItem("agent");
};

export { getUser, setUser, clearUser };
